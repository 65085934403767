import "./src/styles/global.css"

export const onRouteUpdate = ({ location }) => scrollToAnchor(location)
export const onServiceWorkerUpdateReady = () => window.location.reload()

/**
 *
 * @desc - a function to jump to the correct scroll position
 * @param {Object} location -
 * @param {Number} [mainNavHeight] - the height of any persistent nav -> document.querySelector(`nav`)
 */

function scrollToAnchor(location, mainNavHeight = 200) {
  window.scrollTo({ top: 0 })
  if (location && location.hash) {
    setTimeout(function () {
      const item = document.getElementById(`${location.hash.replace("#", "")}`)
      if (item) {
        window.scrollTo({
          top: item.getBoundingClientRect().top - mainNavHeight,
          behavior: "smooth",
        })
      }
    }, 750)
  }
  return true
}

/**
 * Set the default cookie consent for GTM
 */
// Create a gtag function that pushes to a data layer
// array if it exists, or creates a new one if it doesn't
function gtag(...args) {
  if (typeof window !== "undefined") {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(args)
  }
}

// Call the gtag function with default consent options
gtag("consent", "default", {
  analytics_storage: "denied",
  ad_storage: "denied",
})
gtag({ event: "default_consent" })

// Export the gtag function so it can be used by other modules
export { gtag }

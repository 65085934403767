exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-login-tsx": () => import("./../../../src/pages/account/login.tsx" /* webpackChunkName: "component---src-pages-account-login-tsx" */),
  "component---src-pages-account-offers-tsx": () => import("./../../../src/pages/account/offers.tsx" /* webpackChunkName: "component---src-pages-account-offers-tsx" */),
  "component---src-pages-customer-basket-tsx": () => import("./../../../src/pages/customer/basket.tsx" /* webpackChunkName: "component---src-pages-customer-basket-tsx" */),
  "component---src-pages-order-confirmation-tsx": () => import("./../../../src/pages/order-confirmation.tsx" /* webpackChunkName: "component---src-pages-order-confirmation-tsx" */),
  "component---src-pages-preview-page-js": () => import("./../../../src/pages/preview-page.js" /* webpackChunkName: "component---src-pages-preview-page-js" */),
  "component---src-pages-preview-post-js": () => import("./../../../src/pages/preview-post.js" /* webpackChunkName: "component---src-pages-preview-post-js" */),
  "component---src-templates-list-events-js": () => import("./../../../src/templates/list-events.js" /* webpackChunkName: "component---src-templates-list-events-js" */),
  "component---src-templates-list-posts-js": () => import("./../../../src/templates/list-posts.js" /* webpackChunkName: "component---src-templates-list-posts-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

